<template>
    <main id="forgottenPsw">
        <Header />
        <div class="hero-modal-page position-relative">
            <div class="hero-closed-container container-xl">
                <!-- TITLE -->
                <div class="row">                    
                    <div class="col-12 col-md-8 offset-md-2 col-xl-8 offset-xl-2">
                          <div class="card bg-white form-inner-container form-inner-container-shadow form-inner-container--narrow-content ">
                                <div class="card-dialog">
                                    <div class="card-content text-center">                                            
                                        <img 
                                            src="@/assets/imgs/forgot-pw.svg" 
                                            class="img-fluid"                                                     
                                        >
                                        <h3 class="modal-title text-primary fw-700 text-center w-100 py-3">Új jelszó</h3>
                                        <ValidationObserver 
                                            v-if="mode == 'normal'" 
                                            ref="observer" 
                                            v-slot="{ handleSubmit }"
                                        >
                                            <form @submit.prevent="handleSubmit(onSubmit)">
                                                <p class="text-center font-secondary text-black">
                                                    Add meg új jelszavad!
                                                </p>
                                                <CustomInput
                                                    type="password"
                                                    class="mt-4 mb-2"
                                                    :label="'Új jelszó'"
                                                    name="newpass_password"
                                                    v-model="form.password"
                                                    rules="required|min:6|alpha_num"
                                                />
                                                <CustomInput
                                                    type="password"
                                                    class="mb-4"
                                                    :label="'Új jelszó még egyszer'"
                                                    name="newpass_pswwconf"
                                                    v-model="form.passconf"
                                                    rules="required|confirmed:newpass_password"
                                                />
                                                <button 
                                                    type="submit" 
                                                    class="btn btn-primary "
                                                >
                                                    Új jelszó mentése
                                                </button>
                                                <div class="alert mb-0 mt-3" :class="alertType">{{submitMessage}}</div>
                                            </form>
                                        </ValidationObserver>
                                        <div v-if="mode == 'success'">
                                            <p class="m-0 font-primary text-primary">Sikeresen módosítottad jelszavad.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>    
                    </div>
                </div>
            </div>
        </div>          
        <Footer />
    </main>
</template>

<script>
import Header from "@/components/Header.vue"
import Footer from "@/components/Footer.vue"
import CustomInput from '@/components/base/CustomInput.vue'
import { ValidationObserver } from 'vee-validate'

export default {
    components: {        
        CustomInput,
        ValidationObserver,
        Header,
        Footer
    },
    data(){
        return{
            mode: "normal",
            form:{
                password: '',
                passconf: ''
            },
            submitMessage: '',
            alertType:'',
            modal:null 
        }
    },
    created(){
        if (document.querySelectorAll('meta[content="noindex, nofollow"]').length === 0) {
            var m = document.createElement('meta'); 
            m.name = 'robots'; 
            m.content = 'noindex, nofollow'; 
            document.head.appendChild(m);
        }
    },
    methods:{   
        onSubmit(){            
            
            this.post('forgottenpassword/changepassword',
                {
                    forgotten_token: this.$route.params.secret, 
                    password: this.form.password,
                    passconf: this.form.passconf 
            }).then(()=>{
               this.submitMessage = 'Sikeres jelszó módosítás'
               this.mode="success"
               this.alertType = 'alert-success'
            }).catch(()=>{
               this.submitMessage = 'Sikertelen módosítás'
               this.alertType = 'alert-warning'
            })
            
        },
        
    }    
   
}
</script>